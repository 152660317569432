import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store/store';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { loginRequest } from '@src/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import AxiosMsal from '@support/api/axiosMsal';

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

const container = document.getElementById('root')!;
const root = createRoot(container);

// create specific Axios instance for the API that handles Msal
export const AxiosApi = new AxiosMsal(msalInstance, loginRequest, process.env.REACT_APP_API_URL || '').instance

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
